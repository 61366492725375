/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unknown-property */
import { useEffect } from "react";
import { navigate } from "gatsby";
import { SVGMap } from "react-svg-map";
import tw, { css } from "twin.macro";

import DiscusMap from "./discus-map";
import { useStatesQuery } from "../../util/queries";
import {
	useStateStore,
	State,
} from "../../util/store/state-store";

type LocationObject = {
	name: string;
	id: string;
	path: string;
};

const mapStyles = css`
	svg {
		width: 100%;
	}

	svg > path:focus {
		outline: none;
	}

	/* svg > path:hover {
						
					} */

	/* TODO: find more effiecient way to style  */

	.active_state {
		stroke: white;
		stroke-width: 6;
		cursor: pointer;
		/* paint-order: stroke; */
	}

	.permanent_state.restricted_state {
		fill: url(#permanent-hatch);
		cursor: pointer;
	}

	.temporary_state.restricted_state {
		fill: url(#temporary-hatch);
		cursor: pointer;
	}

	.state {
		fill: var(--color-oat);
		cursor: pointer;
	}

	.permanent_state {
		fill: var(--color-bourbon);
		cursor: pointer;
	}

	.temporary_state {
		fill: var(--color-teal);
		cursor: pointer;
	}

	.state:hover {
		filter: brightness(85%);
	}

	.state_label {
		fill: var(--color-bitters);
		pointer-events: none;
	}

	.state_label_bg {
		fill: var(--color-oat);
		cursor: pointer;
	}

	.state_label_bg:hover {
		filter: brightness(85%);
	}
`;

const labels = (id: string) => {
	if (id.includes(`label`) && !id.includes(`bg`))
		return `state_label `;
	if (id.includes(`label_bg`)) return `state_label_bg`;
	if (!id.includes(`label`) && !id.includes(`bg`))
		return `state`;
};

const assignClasses = (
	location: LocationObject,
	activeState?: State,
	state?: State
) => {
	const classList = [];

	classList.push(labels(location.id));

	if (state) {
		if (location.id.toUpperCase() === activeState?.abbrv)
			classList.push(`active_state`);

		if (state.spirits?.value !== `0`) {
			if (state.spiritsText?.includes(`permanent`))
				classList.push(`permanent_state`);
			if (state.spiritsText?.includes(`temporary`))
				classList.push(`temporary_state`);
		}

		// if (state.spirits?.label?.includes(`restricted`) || state.spiritsText?.includes(`in-state only`)) classList.push(`restricted_state`);
		if (state.spirits?.label?.includes(`Restricted`))
			classList.push(`restricted_state`);
	}

	return classList.join(` `);
};

const getStateId = (id: string) => {
	return id.slice(0, 2).toUpperCase();
};

const PermanentRestrictedPattern = () => (
	<pattern
		id="permanent-hatch"
		patternUnits="userSpaceOnUse"
		width="4"
		height="8"
		patternTransform="rotate(-45 2 2)"
	>
		<rect
			x="0"
			y="0"
			width="4"
			height="8"
			css={[
				css`
					fill: var(--color-bourbon);
				`,
			]}
		/>
		<path
			d="M -1,2 l 6,0"
			strokeWidth="1"
			css={[
				css`
					stroke: var(--color-light-beige);
				`,
			]}
		/>
	</pattern>
);
const TemporaryRestrictedPattern = () => (
	<pattern
		id="temporary-hatch"
		patternUnits="userSpaceOnUse"
		width="4"
		height="8"
		patternTransform="rotate(-45 2 2)"
	>
		<rect
			x="0"
			y="0"
			width="4"
			height="8"
			css={[
				css`
					fill: var(--color-teal);
				`,
			]}
		/>
		<path
			d="M -1,2 l 6,0"
			strokeWidth="1"
			css={[
				css`
					stroke: var(--color-light-beige);
				`,
			]}
		/>
	</pattern>
);

const Map = () => {
	const { states, setStates, currentState } = useStateStore();
	const { data, isLoading } = useStatesQuery();

	useEffect(() => {
		if (!isLoading && data) {
			const allStates = data.map((state: any) => ({
				stateId: state.id,
				abbrv: state.ACF.state_id,
				name: state.title.rendered,
				jobs: state.ACF.jobs_supported,
				gdp: state.ACF.state_gdp,
				exports: state.ACF.state_export_data,
				wine: state.ACF.yes__no.allowing_wine_dtc_shipping,
				spirits: {
					label:
						state.ACF.yes__no.allowing_spirits_dtc_shipping
							.label,
					value:
						state.ACF.yes__no.allowing_spirits_dtc_shipping
							.value,
				},
				spiritsText: state.ACF.yes__no.spirits_dtc_text,
				consideringDTC:
					state.ACF.yes__no.considering_dtc_legistlation,
			}));

			setStates(allStates);
		}
	}, [isLoading, data]);

	return (
		<div css={[tw`w-full`, mapStyles]}>
			<svg tw="h-0">
				<PermanentRestrictedPattern />
				<TemporaryRestrictedPattern />
			</svg>
			<SVGMap
				map={DiscusMap}
				locationClassName={(location: LocationObject) => {
					const state = states.find(
						(x: State) =>
							x.abbrv === location.id.toUpperCase()
					);
					return assignClasses(location, currentState, state);
				}}
				onLocationClick={(e: any) =>
					navigate(
						`/state-facts?state=${getStateId(
							e.currentTarget.id
						)}`
					)
				}
			/>
		</div>
	);
};

export default Map;
